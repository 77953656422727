body {
	background-color: #0c0c0c;
}

.separator {
	background-color: #252525;
	height: 2px;
	width: 100%;
}

.bio {
	font-size: 16px;
}
@media (max-width: 1024px) {
	.bio {
		font-size: 15px;
	}
}

.right-side {
	background-color: #161616;
}

.invert {
	filter: brightness(0) invert();
}

.link {
	@apply transition-all;
	border-bottom: 1px lightgray solid;
	color: lightgray;
}
.link:hover {
	color: white;
	border-bottom: 1px white solid;
}

.text-field {
	background-color: #0b0b0b;
	color: white;
	outline: none;
}
.text-field:hover,
.text-field:focus {
	background-color: rgb(19, 19, 19);
}

.text-field::placeholder {
	color: #535353;
}
